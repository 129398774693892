import { Heading, Button } from '@okam/brand-ui/index'
import Head from 'next/head'
import { useTranslation } from 'next-i18next'
import { MainLayout } from '@/components/layout/MainLayout'
import { useDirectus } from '@/lib/directus'

export const NotFoundPage = () => {
  const footerProps = useDirectus()
  const { t: tSystem } = useTranslation('system')
  const { t: tCommon } = useTranslation('common')
  return (
    <MainLayout footerProps={footerProps}>
      <Head>
        <title>{tSystem('notFound.title')}</title>
      </Head>
      <div tw="flex h-screen w-screen flex-col items-center justify-center gap-8">
        <Heading as="h1" variant="h1">
          {tSystem('notFound.title')}
        </Heading>
        <Button tw="text-black" as="a" href={`${tCommon('URL.HOME')}`}>
          {tSystem('links.backToHome')}
        </Button>
      </div>
    </MainLayout>
  )
}
