import type { GetStaticPropsContext } from 'next'
import { NotFoundPage, getSystemStaticProps } from '@/features/system/pages'
import { makeStaticProps } from '@/lib/next'

export const getStaticProps = async (context: GetStaticPropsContext) => {
  const { locale = 'en' } = context

  const props = await getSystemStaticProps(locale)

  return makeStaticProps(props)
}

export default function Custom404() {
  return <NotFoundPage />
}
