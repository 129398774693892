import { QueryClient, dehydrate } from '@tanstack/react-query'
import { BadRequest } from '@tsed/exceptions'
import querySettings from 'query/settings'
import { systemConfig } from '../system.config'

export { NotFoundPage } from './NotFoundPage'
export { ErrorPage } from './ErrorPage'

export const getSystemStaticProps = async (locale?: string) => {
  if (locale === undefined) {
    throw new BadRequest('locale is missing')
  }

  const queryClient = new QueryClient()
  const settings = await queryClient.fetchQuery(['settings'], () => querySettings(locale))
  const { i18nNamespaces } = systemConfig

  if (settings == null) {
    return {
      notFound: true,
      i18nNamespaces,
      locale,
    }
  }

  return { i18nNamespaces, locale, props: { dehydratedState: dehydrate(queryClient) } }
}
